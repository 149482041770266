.c-location {
  &__bg {
    height: $spacer * 16;
    background-position: center;
    background-size: cover;
    @include media-breakpoint-up(md) {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 50%;
    }
    @include media-breakpoint-up(lg) {
      width: 60%;
    }
  }

  &__teamphoto {
    height: $spacer * 5;
    width: $spacer * 5;
    @include media-breakpoint-up(lg) {
      height: auto;
      width: auto;
    }
  }

  &__type {
    margin: 0 0 $spacer * 1.5 0;

    &:hover {
      .c-location__type_subtitle {
        border-color: $secondary;
      }

      .c-icon svg path {
        fill: $secondary;
        stroke: $secondary;
      }
    }

    &_line {
      margin-top: 5px;
      display: block;
      height: 18px;
      width: 5px;
      border-radius: 3px;
      margin-right: 10px;
    }

    &_subtitle {
      padding-top: $spacer * 0.4;
      margin-top: $spacer * 0.5;
      border-top: 1px solid $gray-300;
      transition: 0.2s all ease-out;
    }
  }
}
