.c-header {
  width: 100%;
  background-color: $white;
  height: 62px;

  @include media-breakpoint-up(md) {
    height: 140px;
  }

  @include media-breakpoint-up(lg) {
    height: 147px;
  }

  &__primary {
    background: $secondary;
    border-bottom: 5px solid $blue-light;
  }

  &-mobile {
    z-index: 101;
    background-color: $white;
    padding: $spacer 0;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    border-bottom: 1px solid $blue-light;

    body.visible-menu & {
      position: fixed;
    }

    @include media-breakpoint-up(md) {
      display: none;
    }

    &_toggle {
      margin-left: 5px;
      height: 30px;
      border: none;
      color: $secondary;
      background: $white;
      font-family: $font-family-sans;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  &-desktop {
    display: none;

    @include media-breakpoint-up(md) {
      border-bottom: 1px solid $gray-200;
      display: block;
      position: fixed;
      z-index: 101;
      background-color: $white;
      left: 0;
      top: 0;
      width: 100%;
      transform: translateY(-46px);
      transition: transform 0.25s ease-in-out;

      .sticky & {
        transform: translateY(0);
        border-bottom: none;
      }
    }

    &-submenu {
      @include media-breakpoint-up(md) {
        height: 46px;
      }
    }
  }
}

body.admin-bar .c-header-desktop {
  margin-top: 32px;
}
