/*
  Project: rosorum
  Author: Thomas
 */

.h1-small {
  font-size: $font-size-base * 1.8;

  @include media-breakpoint-up(md) {
    font-size: $font-size-base * 2;
  }

  @include media-breakpoint-up(lg) {
    font-size: $font-size-base * 2;
  }
}

.h4-bigger {
  font-size: $font-size-base * 1.8;

  @include media-breakpoint-up(md) {
    font-size: $h3-font-size;
  }
}

h3, .h3 {
  font-family: $font-family-centgoth;
  font-weight: $font-weight-bold;
}

h4, .h4 {
  font-family: $font-family-centgoth;
  font-weight: $font-weight-bold;
}

.lead {
  font-family: $font-family-libre-baskerville;
  line-height: $lead-line-height;
}

.normal {
  font-family: $font-family-centgoth;
}

.smaller {
  font-size: 70%;
}

.c-content {
  h2, .h2 {
    margin-top: 2rem;
  }

  h3, .h3 {
    margin-top: 1.75rem;
  }

  h4, .h4 {
    margin-top: 1.5rem;
  }

  h5, .h5 {
    margin-top: 1.5rem;
  }
}

.font-family-sans {
  font-family: $font-family-libre-baskerville;
}

.font-family-sans-serif {
  font-family: $font-family-centgoth;
}

.font-family-gracefully {
  font-family: $font-family-gracefully;
}
