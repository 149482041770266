/*
  Project: rosorum
  Author: Thomas
 */
@include media-breakpoint-up(md) {
  html {
     font-size: 1.0625rem;
  }
}

body {
  overflow-x: hidden; // hide horizontal
  position: relative;

  &.no-scroll {
    overflow: hidden;
  }
}
