


// Sinds 5.5 wordpress added Width + Height to all images, we have to add HEIGHT AUTO to images in Gutenburg
// https://core.trac.wordpress.org/ticket/50909

.wp-block-image ,
.wp-block-media-text__media {
  img {
    max-width: 100%;
    height: auto;
  }
}

.comment-form-comment > label {
  display: none;
}
