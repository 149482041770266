/*
  Project: rosorum
  Author: Thomas
 */

%controller {
  position: relative;
  cursor: pointer;
  padding: 1em;

  &::selection {
    background: transparent;
  }

  input + span {
    background: white;
    content: '';
    display: inline-block;
    margin: 0 0.5em 0 0;
    padding: 0;
    vertical-align: middle;
    width: 1.33em;
    height: 1.33em;
    border: 2px solid $gray-light;
    border-radius: 3px;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    transition: $transition-base;

    &::after {
      content: '';
      display: block;
      transform: scale(0);
      transition: transform 0.2s;
    }
  }

  @media screen and (min-width: 768px) {
    &:hover input + span {
      border-color: $gray-dark;
    }
  }

  input:active + span {
    box-shadow: 0 4px 8px rgba(#000, 0.15);
  }

  input:focus + span {
    box-shadow: 0 0 0 3px lightblue;
    background: $secondary;
  }

  input:checked + span {
    background: $secondary;
    border-color: $secondary;

    &::after {
      transform: scale(1);
    }
  }

  input {
    position: absolute;
    cursor: pointer;
    opacity: 0;
  }
}

.checkbox {
  @extend %controller;

  input + span {
    border-radius: 2px;

    &::after {
      background: url(../assets/images/icon-checkbox.svg) no-repeat center;
      background-size: 80% auto;
      width: calc(1.33em - 4px);
      height: calc(1.33em - 4px);
    }
  }
}

.radio {
  @extend %controller;

  input + span {
    border-radius: 100%;

    &::after {
      border-radius: 100%;
      margin: 0.4em;
      width: 0.35em;
      height: 0.35em;
    }
  }

  input:checked + span::after {
    background: $white;
  }
}
