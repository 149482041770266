/*
  Project: rosorum
  Author: Thomas
 */

.c-accordion {
  text-align: left;

  .card-body {
    @include media-breakpoint-down(sm) {
      padding: 0.5rem 1rem;
    }
  }

  .card-header {
    padding: 0;
  }

  //&_content {
  //  : 15px 2.5rem 12px 1rem;
  //}

  &_header {
    padding: 0;
    margin-bottom: 0.75rem;
    background: $gray-200;
    button {
      width: 100%;
      text-align: left;
      position: relative;
      padding-top: 15px;
      padding-bottom: 12px;
      white-space: normal;
      color: $body-color;
      border-radius: $border-radius-sm;
      border: 2px solid $white;

      @include media-breakpoint-up(sm) {
        padding: 15px 3rem 12px 1.5rem;
      }

      &.collapsed {
        &:after {
          transform: rotate(0deg);
        }
      }

      &:hover {
        color: $body-color;
        border-radius: $border-radius-sm;
        border: 2px solid $secondary;
      }

      &:after {
        transform: rotate(180deg);
        transition: $transition-base;
        background: url(../assets/images/icon-arrow.svg) no-repeat;
        background-size: 96% auto;
        content: '';
        right: 1rem;
        top: 22px;
        display: block;
        position: absolute;
        width: 16px;
        height: 11px;
        @include media-breakpoint-up(sm) {
          right: 1.5rem;
        }
      }
    }
  }
}
